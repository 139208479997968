<template>
  <v-container class="text-center">
    <img src="@/assets/error.png" />
    <v-btn x-large @click="back()" color="primary" outlined>الرجوع</v-btn>
  </v-container>
</template>

<script>
export default {
  name: "notfound",
  methods: {
    back() {
      if (this.$store.getters.loggedin) {
        if (this.$store.state.superline_user.super_admin) {
          this.$router.push("/companies");
        } else {
          this.$router.push({
            name: this.$store.getters.first_route,
          });
        }
      } else {
        this.$router.push("/");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  max-width: 500px;
  display: block;
  margin: 40px auto;
}
</style>
