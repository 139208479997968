<template>
  <v-app>
    <AppHeader></AppHeader>
    <v-main>
      <router-view />
      <v-snackbar
        timeout="6000000"
        width="auto"
        right
        v-model="$store.state.connection_snack"
      >
        لايوجد اتصال بالأنترنت
        <template v-slot:action="{ attrs }">
          <v-icon color="white" v-bind="attrs">wifi_off</v-icon>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>
<style scoped></style>
<script>
import AppHeader from "@/layout/AppHeader.vue";

export default {
  name: "App",
  data: () => ({}),
  watch: {
    $route(to) {
      if (this.$store.state.break_point <= 991) {
        this.$store.state.drawer = false;
      }
    },
  },
  methods: {
    break_point() {
      this.$store.state.break_point = window.innerWidth;
      let vm = this;

      window.addEventListener(
        "resize",
        function (event) {
          vm.$store.state.break_point = window.innerWidth;
          if (window.innerWidth > 991) {
            vm.$store.state.drawer = true;
          } else {
            vm.$store.state.mini = false;
          }
        },
        true
      );
    },
  },
  components: {
    AppHeader,
  },
  mounted() {
    this.break_point()
  },
};
</script>

<style lang="scss">
#app {
  background-color: #fafafa;
  font-family: "Open Sans", sans-serif;
}

h1 {
  font-size: 25px;
}
h1,
h2,
h3,
h4,
h5 {
  line-height: 1;
  color: #000;
}
.v-application--is-rtl .page_content {
  @media (min-width: 992px) {
    width: calc(100% - 276px);
  }
  @media (max-width: 991px) {
    padding-right: 0 !important;
    // padding-left: 15px !important;
    flex-shrink: 1 !important;
  }
}
.v-card {
  box-shadow: 0px 3px 1px -5px rgb(0 0 0 / 12%), 0px 2px 4px 0px rgb(0 0 0 / 0%),
    0px 1px 10px 0px rgb(0 0 0 / 12%) !important;
}
p {
  margin-bottom: 0 !important;
  color: #000;
}
.container {
  // background-color:red;
  @media (min-width: 1400px) {
    &:not(.container--fluid) {
      max-width: 1400px;
      padding-right: 55px;
      padding-left: 55px;
    }
  }
}
.v-icon.v-icon {
  color: #000;
}
.input {
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 4px;
  height: 40px;
}

.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(
    .v-input--has-state
  )
  > .v-input__control
  > .v-input__slot
  fieldset {
  color: #aeaeae61 !important;
}
.v-input--switch__track {
  width: 45px !important;
}
.v-application--is-rtl
  .v-input--switch.v-input--is-dirty
  .v-input--selection-controls__ripple,
.v-application--is-rtl
  .v-input--switch.v-input--is-dirty
  .v-input--switch__thumb {
  transform: translate(-30px, 0) !important;
}
.v-input.v-input--switch.theme--light.v-input--switch .v-input--switch__thumb {
  color: #000;
}
.v-input.v-input--switch.theme--light.v-input--switch .v-input--switch__track {
  color: #c0e4b9;
}

th {
  font-weight: bold !important;
  color: #000 !important;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  white-space: nowrap;
}
td {
  white-space: nowrap;
}
.v-stepper {
  box-shadow: none !important;
}
</style>
